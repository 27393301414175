import StoreItemsModule from "@/config/store/StoreItemsModule"
import { withLoading } from "@/config/store/StoreItemsModule/helpers"
import { map } from "lodash-es"

import extractMutations from "./mutations"
import extractActions from "./actions"

const mapFilters = filters => {
  return {
    inventory_group_ids: map(filters.inventoryGroups, "id"),
    prefecture_ids: map(filters.prefectures, "id"),
    search_value: filters.searchValue,
    released: filters.rel
  }
}

const baseURI = "/companies/shops"

const store = new StoreItemsModule({
  baseURI: baseURI,
  presetActions: ["fetch", "delete"],
  withFilters: {
    searchValue: "",
    released: null,
    inventoryGroups: [],
    prefectures: []
  },
  withSorting: true,
  withPagination: true,
  mapFilters
})

store.mergeState({
  item: {}
})

store.mergeMutations(extractMutations())
store.mergeActions(extractActions({ baseURI }), withLoading)

export default store
