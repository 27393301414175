import { api, i18n } from "@/config"
import { handleFailedResponse } from "@/helpers/common"
import { showToast } from "@/helpers/toasts"

export default ({ baseURI }) => ({
  FETCH_ITEM: async ({ commit }, id) => {
    try {
      const { data } = await api.get(`${baseURI}/${id}`)
      commit("SET_ITEM", data.data)
    } catch (e) {
      handleFailedResponse(e)
    }
  },

  CREATE_ITEM: async ({ commit }, params) => {
    try {
      const { data } = await api.post(baseURI, { shop: params })
      commit("SET_ITEM", data.data)
      showToast({
        title: i18n.t("success"),
        text: i18n.t("company_system.shop_settings.shop_created"),
        variant: "success"
      })
    } catch (e) {
      handleFailedResponse(e)
      throw e
    }
  },

  UPDATE_ITEM: async ({ commit }, { id, ...params }) => {
    try {
      const { data } = await api.put(`${baseURI}/${id}`, params)
      commit("SET_ITEM", data.data)
      showToast({
        title: i18n.t("success"),
        text: i18n.t("company_system.shop_settings.shop_updated"),
        variant: "success"
      })
    } catch (e) {
      handleFailedResponse(e)
      throw e
    }
  }
})
